import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Button from '../Button/Button';
import Quote from '../Quote/Quote';
import OrderedList from '../OrderedList/OrderedList';
import Icon from '../Icon/Icon';
import BestRanking from '../BestRanking/BestRanking';
import PremiumRanking from '../PremiumRanking/PremiumRanking';
import Box from '../Box/Box';
import QuotesWidget from '../QuotesWidget/QuotesWidget';
import StaticTable from '../StaticTable/StaticTable';
import Columns from '../Columns/Columns';
import ExpandCollapse from '../ExpandCollapse/ExpandCollapse';
import Stars from "../StarRanking/Stars";
import ColumnRangeChart from '../ColumnRangeChart/ColumnRangeChart';
import BreakLine from '../BreakLine/BreakLine';
import StateCrossLinking from '../StateCrossLinking/StateCrossLinking';
import QuoteButton from '../QuoteButton/QuoteButton';
import ProsCons from '../ProsCons/ProsCons';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import CompanyCrossLinking from '../CompanyCrossLinking/CompanyCrossLinking';
import ListRanking from '../ListRanking/ListRanking';
import Spacing from '../Spacing/Spacing';
import StaticPremiumTable from '../StaticPremiumTable/StaticPremiumTable';
import Badge from '../Badge/Badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "components-playground"
    }}>{`Components Playground`}</h1>
    <p>{`In this playground you can use all the other components together, also some examples of these components were included.`}</p>
    <Playground __position={0} __code={'<h1>Testing Playground</h1>\n<Button className=\"btn-primary\">Primary Button</Button>\n<Button className=\"btn-secondary\">Secondary Button</Button>\n<Button className=\"btn-anchor\">Anchor Button</Button>\n<Quote float=\"center\" width=\"900px\">\n  <h1>\n    LOREM IPSUM\n    <br />\n    DOLOR SIT AMET CONSECTUR\n  </h1>\n</Quote>\n<OrderedList>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n</OrderedList>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Quote,
      OrderedList,
      Icon,
      BestRanking,
      PremiumRanking,
      Box,
      QuotesWidget,
      StaticTable,
      Columns,
      ExpandCollapse,
      Stars,
      ColumnRangeChart,
      BreakLine,
      StateCrossLinking,
      QuoteButton,
      ProsCons,
      CompanyLogo,
      CompanyCrossLinking,
      ListRanking,
      Spacing,
      StaticPremiumTable,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <h1>Testing Playground</h1>
  <Button className="btn-primary" mdxType="Button">
    Primary Button
  </Button>
  <Button className="btn-secondary" mdxType="Button">
    Secondary Button
  </Button>
  <Button className="btn-anchor" mdxType="Button">
    Anchor Button
  </Button>
  <Quote float="center" width="900px" mdxType="Quote">
    <h1>
      LOREM IPSUM
      <br />
      DOLOR SIT AMET CONSECTUR
    </h1>
  </Quote>
  <OrderedList mdxType="OrderedList">
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
  </OrderedList>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      